@import "./main.css";
@import 'react-big-calendar/lib/sass/styles';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
.ant-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-layout-header {
  padding: 0px !important
}

.overflow-table
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-container {
  @apply overflow-x-auto;
}

.ant-table-thead > tr > th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popover-content-w-full {
  .ant-popover-inner-content {
    @apply w-full p-0;
  }
}

// .ant-popover-inner-content {
//   @apply w-full p-0;
// }

.select-project-modal > .ant-modal-content {
  .ant-modal-header {
    padding: 8px 12px;
  }
  .ant-modal-body {
    padding: 8px 12px;
  }
  .ant-modal-footer {
    padding: 8px 12px;
  }
}
.ant-popover-buttons {
  display: flex;
}
.processed-data-table {
	.ant-table-wrapper {
		.ant-spin-nested-loading > .ant-spin-container > .ant-table {
			overflow: scroll;
			.ant-table-container {
				width: max-content;
			}
		}
	}
}

// .rbc-event {
//   background-color: inherit !important;
//   color: inherit !important;
//   border: unset !important;
//   opacity: inherit !important;
// }

.ant-modal-footer {
  display: flex;
}

.ant-modal-confirm-btns {
  display: flex !important;
}

.stretch-to-fill {
  height: calc(100vh - 64px) !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
}

.row-dragging td {
  padding-left: 16px;
  padding-right: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.composition-slider {
  .slick-list {
    @apply h-full;
    .slick-track {
      @apply h-full;
      .slick-slide > div {
        @apply h-full;
      }
    }
  }
}

.draft-row {
  background-color: #f5f5f5 !important;
  color: black !important;
  border-color: #d9d9d9 !important;
  .rbc-event {
    background-color: #f5f5f5 !important;
    color: black !important;
    border-color: #d9d9d9 !important;
  }
}
.published-row {
  color: #096dd9 !important;
  background: #e6f7ff !important;
  border-color: #91d5ff !important;
  .rbc-event {
    background: #096dd9 !important;
    // background: #e6f7ff !important;
    border-color: #91d5ff !important;
  }
}

.active-row {
  color: #389e0d !important;
  background: #f6ffed !important;
  border-color: #b7eb8f !important;
  .rbc-event {
    background: #389e0d !important;
    // background: #f6ffed !important;
    border-color: #b7eb8f !important;
  }
}

.cancelled-row {
  color: #cf1322 !important;
  background: #fff1f0 !important;
  border-color: #ffa39e !important;
  .rbc-event {
    background: #cf1322 !important;
    // background: #fff1f0 !important;
    border-color: #ffa39e !important;
  
  }
}
.completed-row {
  color: #d48806 !important;
  background: #fffbe6 !important;
  border-color: #ffe58f !important;
  .rbc-event {
    background: #d48806 !important;
    // background: #fffbe6 !important;
    border-color: #ffe58f !important;
  }
}


// * {
//   animation-duration: 0.15s !important;
// }

.ant-tooltip {
  max-width: unset !important;
}

.asset-row-selected {
  background-color: rgb(194, 231, 255) !important;
  color: black !important;
  .ant-table-cell-row-hover {
    background-color: inherit !important;
  }
}

.ant-modal-footer {
  @apply flex justify-between;
}

.ant-alert {
  @apply flex items-baseline;
}


// .ant-modal {
//   width: max-content !important
// }

.ant-layout-sider-light .ant-layout-sider-trigger {
  background: transparent !important
}

.ant-layout {
  background: rgb(244, 245, 247) !important;
}

.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
  opacity: 0 !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: 0px !important
}

.ant-menu-item-selected {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}

.rbc-header {
  border: unset !important
}
.rbc-agenda-table {
  border: unset !important
}
.rbc-agenda-content {
  border: unset !important
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border: none !important;

}

.ant-checkbox-group {
  @apply flex flex-col gap-y-2;
}

.filters-collapse > .ant-collapse-item > .ant-collapse-header  {
  padding: 6px 8px !important
}
.campaign-tags-transparent > .ant-select-selector {
  background: transparent !important;
}

.ant-divider-vertical {
  height: unset !important;
}
.ant-layout-sider-collapsed {
  width: 40px !important;
  max-width: 40px !important;
  min-width: 40px !important;
  flex: 0 0 40px !important;
  padding-left: 10px !important
}

.ant-menu-inline-collapsed  {
  margin-top: 10px !important;
  .ant-menu-item {
    &.ant-menu-item-selected {
      border-radius: 6px !important;
    }
  
    .ant-menu-item-icon {
      display: flex !important;
      align-items: center !important;
      height: 100% !important;
    }
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #d1bcd1b4 !important;
}

.resize-handle {
  width: 12px;
  height: 12px;
  border: 2px solid #3b82f6; /* Tailwind's blue-500, for example */
  background: white;
  border-radius: 9999px; /* fully round */
  position: absolute;
  z-index: 10; /* ensure handles are on top of content */
}

/* Position each corner handle slightly "outside" the item */
.top-left {
  top: -5px;
  left: -5px;
  cursor: nwse-resize;
}
.top-right {
  top: -5px;
  right: -5px;
  cursor: nesw-resize;
}
.bottom-left {
  bottom: -5px;
  left: -5px;
  cursor: nesw-resize;
}
.bottom-right {
  bottom: -5px;
  right: -5px;
  cursor: nwse-resize;
}

.ant-drawer-right > .ant-drawer-content-wrapper {
  box-shadow: unset !important;
}

.ant-input-number-prefix {
  margin-inline-end: 8px;
}

.rcp {
  --rcp-background-color: #fff !important;
  --rcp-field-input-color: black !important;
  // --rcp-field-input-border-color: ${({ theme }) => theme.border} !important;
  // --rcp-field-label-color: ${({ theme }) => theme.label} !important;
}

.color-picker > .ant-popover-content > .ant-popover-inner > .ant-popover-inner-content {
  padding: 0px !important;
}